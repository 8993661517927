<template>
  <div>
    <div class="font-size-21 font-weight-bold text-dark">Hi {{userData.first_name}},</div>
    <div class="font-size-36 font-weight-bold text-dark mb-4">Welcome back!</div>
    <div class="row">
      <div class="col-xl-3">
        <div class="card hoverableCard" @click="goToTotalUsers">
          <dashboard-card :backgroundColor="'#4791FF'" :title="'Total Users'" :text="data.total"/>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card hoverableCard" @click="goToPendingUsers">
          <dashboard-card :backgroundColor="'#FFD950'" :title="'Pending Users'" :text="data.pending"/>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card hoverableCard" @click="goToRejectedUsers">
          <dashboard-card :backgroundColor="'#E74C3C'" :title="'Rejected Users'" :text="data.rejected"/>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card hoverableCard" @click="goToActiveUsers">
          <dashboard-card :backgroundColor="'#02BC77'" :title="'Active Users'" :text="data.active"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
              <bar-chart :chartdata="barData" :options="barOptions" :height="150"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from './data.json'
import DashboardCard from './dashboardCard'
import BarChart from '@/views/charts/chartjs/BarChart'
import { mapActions, mapGetters } from 'vuex'

const barData = {
  labels: [],
  datasets: [
    {
      label: 'Active',
      backgroundColor: '#02BC77',
      borderWidth: 1,
      data: [],
    },
    {
      label: 'Pending',
      backgroundColor: '#FFD950',
      borderWidth: 1,
      data: [],
    },
    {
      label: 'Total',
      backgroundColor: '#4791FF',
      borderWidth: 1,
      data: [],
    },
    {
      label: 'Rejected',
      backgroundColor: '#E74C3C',
      borderWidth: 1,
      data: [],
    },
  ],
}

const barOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: false,
      },
    ],
    yAxes: [
      {
        stacked: false,
      },
    ],
  },
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      usePointStyle: true,
      fontSize: 10,
      padding: 35,
    },
  },
}

export default {
  components: {
    BarChart,
    DashboardCard,
  },
  data() {
    return {
      tableData: data.tableData,
      barData,
      barOptions,
      ready: false,
      data: {},
    }
  },
  async mounted() {
    await this.getInfo()
    this.isApproved
  },
  methods: {
    ...mapActions('admin', {
      getData: 'DASHBOARD_INIT',
    }),
    async getInfo() {
      try {
        this.data = await this.getData()
        barData.labels = this.data.chartData.labels
        barData.datasets[0].data = this.data.chartData.data.active
        barData.datasets[1].data = this.data.chartData.data.pending
        barData.datasets[2].data = this.data.chartData.data.total
        barData.datasets[3].data = this.data.chartData.data.rejected
      } catch (e) {
        console.log(e)
      } finally {
        this.ready = true
      }
    },
    goToTotalUsers() {
      this.$router.push('users')
    },
    goToPendingUsers() {
      this.$router.push('pending/users')
    },
    goToActiveUsers() {
      this.$router.push('active/users')
    },
    goToRejectedUsers() {
      this.$router.push('rejected/users')
    },
  },
  computed: {
    ...mapGetters('user', ['user']),
    userData() {
      return this.$store.state.auth.user
    },
  },
}
</script>

<style type="scss">
  .hoverableCard {
    cursor: pointer
}
</style>
