<template>
  <div class="pt-3" :class="$style.container">
    <div :class="[$style.status]" :style="{'background-color':backgroundColor}"></div>
    <div class="d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4">
      <div class="mr-auto">
        <div class="text-uppercase font-size-18">{{title}}</div>
        <div class="font-weight-bold font-size-36 text-dark">{{text}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral6',
  props: {
    backgroundColor: {
      type: String,
      default: 'bg-primary',
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    text: {
      type: Number,
      default: 0,
      required: false,
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
